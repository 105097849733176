import React from 'react'
import { Link } from 'gatsby';
import { BiChevronRightCircle } from "react-icons/bi";
import './index.css';


function index({ href, text }) {

    return (

        <Link to={href} className='btn'>
            <span>{text}</span>
            <BiChevronRightCircle className='chev_icon' />
        </Link>

    )
}

export default index