import React, { useState } from "react"
import Meta from "../../components/meta"
import Layout from "../../components/layout"
import logo from "../../images/favicon.png"
import RoundedButton from "../../components/rounded-button"
import "./index.css"

export const Head = () => {
  // console.log("blogs: ", blogs);

  let meta = {
    title: "Page not found",
    url: "https://www.digitallaboratory.net/company/404",
    image: logo,
  }

  return <Meta meta={meta} />
}

function Index() {
  return (
    <Layout>
      <div className="wrapper404">
        <div className="wrapper404__center">
          <h2 className="wrapper404__center-title">
            Oops! That page can't be found.
          </h2>
          <p className="wrapper404__center-para">
            It looks like nothing was found at this location.
          </p>
        </div>
        <RoundedButton href="/" text="Return to Home" />
      </div>
    </Layout>
  )
}

export default Index
